<template>
  <div class="home-wrap">
    <div class="home_header">
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        codeForm: {
          code: ''
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../home/home.scss";
</style>
